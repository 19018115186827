import * as Types from '../types/types.generated';

import { gql } from '@apollo/client';
import { UserFieldsFragmentDoc } from './userFields.generated';
export type RequirementGuidelineFieldsFragment = { __typename?: 'RequirementDerivationGuideline', id: string, name: string, isLatest: boolean, createdBy: { __typename?: 'User', givenName?: string | null, id: string, mail?: string | null, name: string, surname?: string | null, userPrincipalName?: string | null }, modifiedBy: { __typename?: 'User', givenName?: string | null, id: string, mail?: string | null, name: string, surname?: string | null, userPrincipalName?: string | null } };

export type AttributesGuidelineFieldsFragment = { __typename?: 'AttributesDerivationGuideline', id: string, name: string, isLatest: boolean, createdBy: { __typename?: 'User', givenName?: string | null, id: string, mail?: string | null, name: string, surname?: string | null, userPrincipalName?: string | null }, modifiedBy: { __typename?: 'User', givenName?: string | null, id: string, mail?: string | null, name: string, surname?: string | null, userPrincipalName?: string | null } };

export const RequirementGuidelineFieldsFragmentDoc = gql`
    fragment RequirementGuidelineFields on RequirementDerivationGuideline {
  id
  name
  isLatest
  createdBy {
    ...UserFields
  }
  modifiedBy {
    ...UserFields
  }
}
    ${UserFieldsFragmentDoc}`;
export const AttributesGuidelineFieldsFragmentDoc = gql`
    fragment AttributesGuidelineFields on AttributesDerivationGuideline {
  id
  name
  isLatest
  createdBy {
    ...UserFields
  }
  modifiedBy {
    ...UserFields
  }
}
    ${UserFieldsFragmentDoc}`;