import {
	ConstrainMode,
	DefaultButton,
	IPanelProps,
	IRenderFunction,
	Label,
	Panel,
	PanelType,
	PrimaryButton,
	Stack,
} from '@fluentui/react';
import {useEntityContext} from 'components/EntityPage/EntityContext';
import {ControlledTextField} from 'components/hookForms';
import {LoadWrapper} from 'components/LoadWrapper';
import {ParagraphsList} from 'features/RegulatoryDocuments/components/DocumentDetails';
import {useParagraphsContext} from 'features/RegulatoryDocuments/components/DocumentDetails/ParagraphsContext';
import {useAssignRegulatoryDocumentParagraphsRequirementsMutation} from 'features/RegulatoryDocuments/hooks/useAssignRegulatoryDocumentParagraphsRequirement.generated';
import {useCreateRequirementMutation} from 'features/RegulatoryDocuments/hooks/useCreateRequirement.generated';
import {useCommand} from 'hooks';
import React from 'react';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {ConvoluteType, Requirement, UserRole} from 'types';
import {useRequirementParagraphsLazyQuery} from '../hooks/requirementParagraphs.generated';
import {
	GetAllRequirementsDocument,
	GetRequirementsFormDataQuery,
} from '../hooks/requirements.generated';
import {mapToRef} from 'helpers';
import {useGetConvolutesQuery} from 'features/AdminSection/hooks/convolutes.generated';
import {useGetGdprRuleQuery} from 'features/AdminSection/hooks/gdprRules.generated';
import {FormMode} from 'components/EntityPage/EntityPage.types';
import {RequirementFromListPage} from '../requirementPage.types';
import {RequirementFormElements} from './RequirementFormElements';

enum CreateVersionStep {
	CreateRequirement,
	ReassignParagraphs,
}

export const NewRequirementVersionPanel: React.FC<{
	formData: NonNullable<GetRequirementsFormDataQuery> | undefined;
}> = ({formData}) => {
	const {t} = useTranslation('features/requirements', {
		keyPrefix: 'NewRequirementVersionPanel',
	});
	const [createRequirement] = useCreateRequirementMutation();
	const [assignRequirements] =
		useAssignRegulatoryDocumentParagraphsRequirementsMutation();
	const [showPanel, setShowPanel] = React.useState(false);
	const [step, setStep] = React.useState(CreateVersionStep.CreateRequirement);
	const [newRequirementId, setNewRequirementId] = React.useState<
		string | undefined
	>(undefined);
	const [isSubmitting, setIsSubmitting] = React.useState(false);
	const {selectedItems} = useEntityContext<Requirement>();
	const {selectedParagraphs} = useParagraphsContext();
	const {handleSubmit, control, reset} = useForm<RequirementFromListPage>({
		reValidateMode: 'onSubmit',
		mode: 'all',
	});
	const [loadAssignedParagraphs, {data, loading}] =
		useRequirementParagraphsLazyQuery();
	const assignedParagraphs = React.useMemo(
		() => data?.requirement?.assignedParagraphs ?? [],
		[data],
	);

	const panelType = React.useMemo(
		() =>
			step === CreateVersionStep.CreateRequirement
				? PanelType.medium
				: PanelType.extraLarge,
		[step],
	);

	const {data: convolutes} = useGetConvolutesQuery();
	const convolute = React.useMemo(
		() =>
			convolutes?.convolutes?.find(
				c => c.convoluteType === ConvoluteType.Requirement,
			),
		[convolutes],
	);

	const {data: gdprRule} = useGetGdprRuleQuery({
		variables: {
			id: convolute?.gdprRule?.id ?? '',
		},
	});

	const rule = React.useMemo(() => gdprRule?.gdprRule, [gdprRule]);

	const handleSaveClick = React.useCallback(() => {
		if (step === CreateVersionStep.CreateRequirement) {
			handleSubmit(requirement => {
				setIsSubmitting(true);
				createRequirement({
					variables: {
						input: {
							name: requirement.name,
							status: requirement.status,
							vexClusterRefs: mapToRef(requirement?.vexClusters),
							systemLevelRefs: mapToRef(requirement.systemLevels),
							driveVariantRefs: mapToRef(requirement.driveVariants),
							gearboxVariantRefs: mapToRef(requirement.gearboxVariants),
							bodyworkVariantRefs: mapToRef(requirement.bodyworkVariants),
							engineVariantRefs: mapToRef(requirement.engineVariants),
							activeTypeMarketRefs: mapToRef(requirement.activeTypeMarkets),
							vehicleCategoryRefs: mapToRef(requirement.vehicleCategories),
							category: requirement.category,
							definition: requirement.definition,
							deletionPeriod: rule?.deletionPeriod ?? 0,
							willBeAnonymized: rule?.anonymize ?? false,
							successorRef: {id: requirement?.id},
							objectCategory: requirement.objectCategory,
							requirementGuidelineVersion:
								requirement.requirementGuidelineVersion,
							attributesGuidelineVersion:
								requirement.attributesGuidelineVersion,
						},
					},
					/**
					 * ! Important
					 *
					 * This causes the panel to close before reaching the second step.
					 */
					refetchQueries: [GetAllRequirementsDocument],
				})
					.then(res => {
						const newReqId = res.data?.createRequirement.requirement?.id;
						if (newReqId) {
							setNewRequirementId(newReqId);
							setStep(CreateVersionStep.ReassignParagraphs);
						}
					})
					.finally(() => setIsSubmitting(false));
			})();
		} else if (newRequirementId) {
			const groupedByDocId = groupItemBy(selectedParagraphs, 'parent.id');
			const assignPromises = Object.keys(groupedByDocId).map(regDocIdid => {
				const paragraphs = groupedByDocId[regDocIdid];
				return assignRequirements({
					variables: {
						input: {
							paragraphIds: paragraphs.map(p => p.id),
							regulatoryDocumentId: regDocIdid,
							requirementRefs: [{id: newRequirementId}],
						},
					},
				});
			});
			setIsSubmitting(true);
			Promise.all(assignPromises).finally(() => {
				setShowPanel(false);
				setStep(CreateVersionStep.CreateRequirement);
				setIsSubmitting(false);
			});
		}
	}, [selectedItems, step, selectedParagraphs, newRequirementId]);

	const handleCancelClick = React.useCallback(() => {
		setShowPanel(false);
		setStep(CreateVersionStep.CreateRequirement);
	}, []);

	useCommand(
		{
			key: 'newRequirementVersion',
			priority: 10,
			text: t('CreateNewVersion'),
			onClick() {
				reset(selectedItems[0]);
				loadAssignedParagraphs({
					variables: {
						id: selectedItems[0].id,
					},
				});
				setShowPanel(true);
			},
			iconProps: {iconName: 'PageAdd'},
			disabled: selectedItems.length !== 1,
			roles: [UserRole.SystemAdministrator, UserRole.Vex],
		},
		[selectedItems],
	);

	const onRenderFooterContent: IRenderFunction<IPanelProps> | undefined =
		React.useCallback(
			() => (
				<form>
					<Stack horizontal tokens={{childrenGap: 8}}>
						<PrimaryButton onClick={handleSaveClick} disabled={isSubmitting}>
							{step === CreateVersionStep.CreateRequirement
								? t('Next')
								: t('Save')}
						</PrimaryButton>
						<DefaultButton onClick={handleCancelClick} disabled={isSubmitting}>
							{t('Cancel')}
						</DefaultButton>
					</Stack>
				</form>
			),
			[step, handleSaveClick, handleCancelClick, isSubmitting],
		);

	return (
		<Panel
			headerText={t('NewRequirementVersion')}
			isOpen={showPanel}
			onDismiss={handleCancelClick}
			onRenderFooterContent={onRenderFooterContent}
			type={panelType}
			isFooterAtBottom={true}
			styles={{
				main: {
					overflow: 'hidden',
				},
			}}
		>
			<LoadWrapper loading={isSubmitting} text={`${t('Saving')}...`}>
				<>
					{step === CreateVersionStep.CreateRequirement && (
						<>
							<ControlledTextField
								required={true}
								label={t('Name')}
								control={control}
								name={'name'}
								rules={{required: t('RequiredField')}}
								tooltipHostProps={{shouldGetTextFromContext: true}}
							/>
							<RequirementFormElements
								control={control}
								formData={formData}
								mode={FormMode.Create}
								id={selectedItems[0]?.id}
							/>
						</>
					)}
					{step === CreateVersionStep.ReassignParagraphs && (
						<LoadWrapper loading={loading}>
							<Label>{t('ChooseParagraphs')}</Label>
							<ParagraphsList
								paragraphs={assignedParagraphs}
								includeRegulationAndVersionName
								constrainMode={ConstrainMode.horizontalConstrained}
								fixedHeight={'calc(100vh - 180px)'}
							/>
						</LoadWrapper>
					)}
				</>
			</LoadWrapper>
		</Panel>
	);
};

function groupItemBy<T extends {[key: string]: any}>(
	array: T[],
	property: string,
): {[key: string]: T[]} {
	const hash: any = {};
	const props = property.split('.');
	for (const item of array) {
		const key = props.reduce(function (acc, prop) {
			return acc?.[prop];
		}, item);
		if (!hash[key]) hash[key] = [];
		hash[key].push(item);
	}

	return hash;
}
