export const getConfig = () => {
	const REACT_APP_AUTHORITY =
		process.env.REACT_APP_AUTHORITY ||
		'https://login.microsoftonline.com/56564e0f-83d3-4b52-92e8-a6bb9ea36564/';
	const REACT_APP_CLIENT_ID =
		process.env.REACT_APP_CLIENT_ID || '883e89d4-6062-49b9-8b3c-c084da6ac2f4';
	const REACT_APP_SERVICE_URL =
		process.env.REACT_APP_SERVICE_URL || 'http://localhost:4000/graphql';
	const REACT_APP_REDIRECT_URI =
		process.env.REACT_APP_REDIRECT_URI || 'http://localhost:3000';
	const REACT_APP_ADOBE_CLIENT_ID =
		process.env.REACT_APP_ADOBE_CLIENT_ID || '8c0cd670273d451cbc9b351b11d22318';
	const REACT_APP_MSAL_SCOPE =
		process.env.REACT_APP_MSAL_SCOPE ||
		'api://883e89d4-6062-49b9-8b3c-c084da6ac2f4/access';

	const REACT_APP_ENV = process.env.REACT_APP_ENV || 'pdv';
	const REACT_APP_BUILD_VERSION =
		process.env.REACT_APP_BUILD_VERSION || '1.0.6';
	const REACT_APP_BUILD_FULLVERSION =
		process.env.REACT_APP_BUILD_FULLVERSION || '1.0.6-605';
	const REACT_APP_BUILD_INFORMATIONAL_VERSION =
		process.env.REACT_APP_BUILD_INFORMATIONAL_VERSION ||
		'1.0.6-605+Branch.test.Sha.895f46c614075427f84eb94bd91119a6bd32926f';
	const REACT_APP_BUILD_NUMBER = process.env.REACT_APP_BUILD_NUMBER || '652235';
	const REACT_APP_BUILD_SHA =
		process.env.REACT_APP_BUILD_SHA ||
		'895f46c614075427f84eb94bd91119a6bd32926f';
	const REACT_APP_BUILD_TIMESTAMP_UTC =
		process.env.REACT_APP_BUILD_TIMESTAMP_UTC || '2024-10-18T13:35:08+00:00';

	return {
		REACT_APP_AUTHORITY,
		REACT_APP_CLIENT_ID,
		REACT_APP_SERVICE_URL,
		REACT_APP_REDIRECT_URI,
		REACT_APP_ADOBE_CLIENT_ID,
		REACT_APP_MSAL_SCOPE,
		REACT_APP_BUILD_VERSION,
		REACT_APP_BUILD_FULLVERSION,
		REACT_APP_BUILD_INFORMATIONAL_VERSION,
		REACT_APP_BUILD_NUMBER,
		REACT_APP_BUILD_SHA,
		REACT_APP_BUILD_TIMESTAMP_UTC,
		REACT_APP_ENV,
	};
};
